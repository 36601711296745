var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn p-relative",
      class: { loading: _vm.loading },
      on: { click: _vm.target }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("input", {
        ref: "file",
        attrs: {
          type: "file",
          hidden: "",
          accept: _vm.type ? _vm.type.join(",") : ""
        },
        on: { change: _vm.onChange }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }